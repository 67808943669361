<template>
	<div>
		<b-modal id="lostChangeModal" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
			bodyClass="text-center" footerClass='p-2' :centered='true' @ok="dispelModal" :title="$t('General.UnsavedInfo')">
			<p class="my-2 text-left">{{ $t("General.UnsavedInfoText") }}</p>
		</b-modal>
		<b-modal :id="data.name" :title="$t(data.title)" hide-footer dialog-class="cg-model-dialog" @show="blankForm" @hidden="cleanForm" @close="manageClose" no-close-on-backdrop>
			<cg-localized-alert :alertVariant="alertVariant" :ref="alertId" :alertMessage="alertMessage" 
				:alertParams="alertParams" :alertCount="alertCount"></cg-localized-alert>
			<b-form @submit.prevent="submitForm" @reset.prevent="exitForm">
				<b-row>
					<b-col v-for="(col, count) in numColumns" :key="count" :class="{ 'col-sm-12 col-md-6' : numColumns == 2}">
						<div v-for="(field, index) in data.fields" :key="index">
							
							<!-- To improve usability, fields are split into columns if they exceed 4 -->
							<b-form-group v-if="index>=itemPerRow*count && index<itemPerRow*(count+1) && field.display" :id="'input-group-' + field.id" 
								:label="$tc(field.label, field.plural)" :description="$t(field.description)" :label-for="field.id">

								<!-- Text widget -->
								<b-form-input v-if="textTypes.includes(field.type)" :id="field.id" :disabled="field.disabled" 
									:required="field.required" :type="field.type" :autofocus="index==0" autocomplete="new-password" 
									v-model="dataItem[field.id]" :placeholder="$t(field.placeholder)" @change="validate($event, field)"
									:class="{'form-field-error': field.error}" :min="field.min" :max="field.max" :step="field.step"
								></b-form-input>

								<b-form-file v-if="field.type=='file'" v-model="dataItem[field.id]" size="md" :placeholder="$t(field.placeholder)"
									:accept=field.acceptFileType :id="field.id" :ref="field.id" :required="field.required" :class="{'form-field-error': field.error}">
								</b-form-file>

								<!-- Lowercase Text widget -->
								<input v-if="field.type=='lowercase'" :id="field.id" :disabled="field.disabled" 
									:required="field.required" :type="field.input_type" :autofocus="index==0" autocomplete="new-password" 
									v-model.trim="dataItem[field.id]" :placeholder="$t(field.placeholder)"
									:class="{'form-field-error': field.error}" class="form-control"
									@input="forceUpdate($event.target.value.toLowerCase(), field.id)"
									@change="validate($event.target.value.toLowerCase(), field)">

								<!-- Tag input widget -->
								<tags-input v-if="field.type=='tag-input'" :id="field.id" :disabled="field.disabled" :required="field.required"
									v-model="dataItem[field.id]" :existing-tags="field.options" :typeahead="true" typeahead-style="dropdown"
									:placeholder="$t(field.placeholder)" :only-existing-tags="true" :typeahead-hide-discard="true" 
									@change="manageTag(field)" @tag-removed="manageTag(field)">
								</tags-input>
								
								<!-- Select widget -->
								<b-form-select v-if="field.type=='select'" :id="field.id" :disabled="field.disabled" 
									:required="field.required" v-model="dataItem[field.id]" :options="field.options" @change="change($event, field);validate($event, field)">
								</b-form-select>

								<!-- Dropdown widget -->
								<div v-if="field.type=='icon-selector'">
									<b-dropdown :id="field.id" :disabled="field.disabled" :required="field.required" :text="iconSelector.title"
										:placeholder="$t(field.placeholder)" v-model="dataItem[field.id]" class="mr-2 modal-border-light" variant="transparent">

										<template #button-content>
											<img v-if="iconSelector.item" :src="`${iconSelector.item.icon}`" alt="" border="0" height="30" width="30" class="mr-2">
											{{ iconSelector.item ? iconSelector.item.name : iconSelector.title }}
										</template>

										<b-dropdown-item-button v-for="(img, index) in field.options" :key="index" @click="forceUpdate(img.name, field.id); iconSelector.item=img">
											<img :src="`${img.icon}`" alt="" border="0" height="30" width="30" class="mr-2">{{ img.name }}
										</b-dropdown-item-button>
									</b-dropdown>
									
									<b-button variant="transparent" v-if="iconSelector.item" @click="iconSelector.item=null;" class="font-italic font-14">
										<font-awesome-icon class="vertical-align-middle mr-1" :icon="['fas', 'trash-alt']"/>
										{{ $t('General.Delete') }}
									</b-button>
								</div>

								<!-- Text area widget -->
								<b-form-textarea v-if="field.type=='textarea'" :id="field.id" :disabled="field.disabled" 
									:required="field.required" v-model="dataItem[field.id]" :rows="field.rows" :max-rows="field.maxRows"
									:placeholder="$t(field.placeholder)">
								</b-form-textarea>

								<!-- Simple Checkbox widget -->
								<b-form-checkbox v-if="field.type=='simple-checkbox'" class="btn-status float-left align-left mb-2 w-100" switch size="lg" button-variant="info" 
									v-model="dataItem[field.id]" :value="field.value" :disabled="field.disabled" :unchecked-value="field.unchecked_value" @change="forceUpdate($event, field.id);change($event, field)">
									<span class="sr-only">{{ field.label }}</span>
								</b-form-checkbox>

								<!-- Checkbox widget -->
								<b-form-checkbox-group v-if="field.type=='checkbox'" class="option-list" :id="field.id + '-checkbox-group'" 
									v-model="dataItem[field.id]" switches size="lg" :name="field.id">
									<div v-if="field.dataset && field.dataset.length > 0">
										<b-row v-for="(option, index) in field.dataset" :key="index" :id="'option-' + index" class="row-item">
											<b-col class="option-name text-truncate">{{ option.name }}</b-col>
											<b-col cols="auto" align-self="center" v-if="!field.disabled">
												<b-form-checkbox :value="option[field.key]" class="mr-0">
													<span class="sr-only">{{ option.name }}</span>
												</b-form-checkbox>
											</b-col>
										</b-row>
									</div>
									<div v-else>
										<b-row class="row-item">
											<b-col class="option-name text-truncate">{{ $t(field.emptyDatasetMessage) }}</b-col>
										</b-row>
									</div>
								</b-form-checkbox-group>

								<!-- Read-only list widget -->
								<b-form-checkbox-group v-if="field.type=='check-list'" class="option-check-list" :id="field.id + '-checklist-group'" 
									v-model="dataItem[field.id]" :name="field.id">
									<div v-if="field.dataset && field.dataset.length > 0">
										<b-row v-for="(option, index) in field.dataset" :key="index" class="row-item option-name text-truncate">
											<span class="ml-2">{{index+1}}) {{ option.name }}</span>
										</b-row>
									</div>
									<div v-else>
										<b-row class="row-item">
											<b-col class="option-name text-truncate">{{ $t(field.emptyDatasetMessage) }}</b-col>
										</b-row>
									</div>
								</b-form-checkbox-group>

								<!-- Tabbed checkbox widget-->
								<b-card no-body v-if="field.type=='tabbed-checkbox'">
									<b-tabs card>
										<b-tab :title="section.label" v-for="(section, j) in field.sections" :key="j">
											<b-form-checkbox-group class="option-list h-360" :id="field.id + '-checkbox-group'" @change="forceUpdate($event, field.id)"
												v-model="field.model" switches size="lg" :name="field.id">
												<b-row v-for="(option, index) in field.dataset[section.key]" :key="index" :id="'option-' + index" class="row-item">
													<b-col class="option-name text-truncate">{{ option.name }}</b-col>
													<b-col cols="auto" align-self="center">
														<b-form-checkbox :value="option[field.key]" class="mr-0">
															<span class="sr-only">{{ option.name }}</span>
														</b-form-checkbox>
													</b-col>
												</b-row>
											</b-form-checkbox-group>
										</b-tab>
									</b-tabs>
								</b-card>
							</b-form-group>
						</div>
					</b-col>
				</b-row>
				<b-row align-h="end" class="cg-modal-footer mt-4 pt-3 pb-1">
					<b-col>
						<b-button type="button" variant="darknavy" 
							@click="customEvent(data.customEvent.handler)" v-if="data.customEvent && data.customEvent.permission" :disabled="data.customEvent.disabled">{{ $t(data.customEvent.label) }}</b-button>
					</b-col>
					<b-col cols="auto">
						<b-button type="reset">{{ $t('General.Cancel') }}</b-button>
						<b-button type="submit" :disabled="!formValid" variant="primary">{{ $t(data.label) }}</b-button>
					</b-col>
				</b-row>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
export default {
	name: "cg-modal",
	props: {
		data: {
			title: String,
			label: String,
			name: String,
			item: Object,
			submitHandler: String,
			fields: Array
		}
	},
	data() {
		return {
			textTypes: ['text', 'password', 'email', 'number', 'search', 'url', 'tel', 'date', 'time', 'range', 'color'],
			formValid: true,
			alertVariant: 'danger',
			alertMessage: '',
			alertCount: 1,
			alertParams: {},
			iconSelector: {
				images: [],
				item: null,
				title: this.$t('General.SelectOption')
			}
		};
	},
	computed: {
		dataFields() {
			// Allow for text input field to occupy more than one line and count as a bigger widget
			let sum = 0;
			this.data.fields.forEach((el) => {
				if(el.lineheight) {
					sum += el.lineheight;
				} else {
					sum += 1;
				}
			});
			
			return sum;
		},
		numColumns() {
			return (this.data && this.data.fields) ? Math.ceil(this.dataFields / this.itemPerRow) : 0;
		},
		itemPerRow() {
			return (this.data && this.data.fields && this.dataFields > 4) ? Math.ceil(this.dataFields / 2) : 4;
		},
		dataItem: {
			get() {
				return Object.assign({}, this.data.item);
			},
			set(value) {
				console.debug("Modal - set new value:", value);
			}
		},
		alertId() {
			return 'alert-' + this.data.name;
		}
	},
	methods: {
		forceUpdate(values, key) {
			this.dataItem[key] = values;
		},
		submitForm() {
			this.$emit('update', this.dataItem);
			this.dispelModal();
		},
		exitForm() {
			if(JSON.stringify(this.data.item) !== JSON.stringify(this.dataItem)) {
				this.$bvModal.show('lostChangeModal');
			} else {
				// Hide the modal manually
				this.$nextTick(() => {
					this.$bvModal.hide(this.data.name);
				})
			}
		},
		manageClose(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.exitForm();
		},
		dispelModal() {
			this.$bvModal.hide('lostChangeModal');
			this.$bvModal.hide(this.data.name);
		},
		customEvent(handler) {
			this.$emit(handler, this.dataItem);
			this.dispelModal();
		},
		cleanForm() {
			this.data.item = [];
			this.data.fields.forEach(i => {
				delete i.error;
			})
		},
		blankForm() {
			this.iconSelector.item = null;
		},
		validate(value, field) {
			field.error = false;
			if(typeof field.validator == 'function' && value != '') {
				this.formValid = field.validator(value);
				if(!this.formValid) {
					field.error = true;
					if(field.errorAlertMessage) {
						this.alertMessage = field.errorAlertMessage;
						if(field.errorAlertParams && typeof field.errorAlertParams == 'function') {
							this.alertParams = field.errorAlertParams();
						}
						if(field.errorAlertCount && typeof field.errorAlertCount == 'function') {
							this.alertCount = field.errorAlertCount();
						}
						this.$refs[this.alertId].showAlert();
					} 
				}
			}
		},
		change(selected, field) {
			if(field.change && typeof field.change == 'function') {
				field.change(selected);
			}
		},
		manageTag(field) {
			if(this.dataItem[field.id].length == 0) {
				field['clean'](this.dataItem, field.id);
			} else {
				field['manage'](this.dataItem, field.id);
			}
		}
	}
}
</script>
<style lang="less">
.cg-model-dialog {
	.cg-modal-footer {
		border-top: @cg-border-light;
		button:last-of-type {
			margin: 0 0 0 20px;
		}
		button:only-of-type {
			margin: 0;
		}
	}
	.option-list {
		min-height: auto;
		max-height: 206px;
		border: @cg-border-light;
		border-radius: 0.3rem;
		overflow: auto;
		.row-item {
			.option-name {
				line-height: 50px;
			}
			.custom-switch {
				cursor: pointer;
			}
			min-height: 50px;
			align-content: center;
			border-bottom: @cg-border-light;
			margin: unset;
			&:last-of-type {
				border-bottom: none;
			}
		}
		&.h-360 {
			max-height: 360px;
		}
	}
	.option-check-list {
		min-height: auto;
		max-height: 212px;
		border: @cg-border-light;
		border-radius: 0.3rem;
		overflow: auto;
		.row-item {
			.option-name {
				line-height: 30px;
			}
			min-height: 30px;
			align-content: center;
			margin: unset;
			&:nth-child(odd) {
				background-color:#e9ecef;
			}
		}
		&.h-360 {
			max-height: 360px;
		}
	}
	.modal-border-light {
		border: 1px solid #ced4da;
		border-radius: .25rem;
	}
}
// Small devices (landscape phones, 576px and up)
@media only screen and (min-width: 575px) {
	.cg-model-dialog.modal-dialog {
		max-width: 500px;
	}
}
// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 767px) {
	.cg-model-dialog.modal-dialog {
		max-width: 700px;
	}
}
// Large devices (desktops, 992px and up)
@media only screen and (min-width: 991px) {
	.cg-model-dialog.modal-dialog {
		max-width: 800px;
	}
}
// Extra large devices (large desktops, 1200px and up)
@media only screen and (min-width: 1199px) {
	.cg-model-dialog.modal-dialog {
		max-width: 900px;
	}
}
</style>