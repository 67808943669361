import axios from '../plugins/axios.js'

class TemplateService {

	getTemplateDetails(templateId) {
		return axios.get(`/template/${templateId}`);
	}

	getTemplatesDetails(body) {
		return axios.post(`/template/contents`, body);
	}

	getTemplateHints(templateId, language, searchText, page, numRes, sortBy, sortDesc) {
		let opt = { params: { searchText: searchText, page: page, numRes: numRes, sortBy: sortBy, sortDesc: sortDesc } };
		return axios.get(`/template/${templateId}/hint/${language}`, opt);
	}

	updateTemplateHint(templateId, hintId, body) {
		return axios.put(`/template/${templateId}/hint/${hintId}`, body);
	}

	deleteTemplateHint(templateId, hintId) {
		return axios.delete(`/template/${templateId}/hint/${hintId}`);
	}

	createTemplateHint(templateId, body) {
		return axios.post(`/template/${templateId}/hint`, body);
	}

	getHintIcons() {
		return axios.get(`/template/hint/icons`);
	}

	getTemplateAttachments(companyId, scope) {
		return axios.get(`/template/attachments`, { params: { companyId: companyId, scope: scope } });
	}

	downloadTemplateAttachment(id, language) {
		let opt = { params: { id: id, language: language } };
		return axios.get(`/template/attachments/content`, opt);
	}

	updateTemplateAttachment(body) {
		return axios.put(`/template/attachments`, body, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}
	
	copyAttachment(attachmentId, body) {
		return axios.post(`/template/attachments/${attachmentId}/copy`, body);
	}

	getImageRepo() {
		return axios.get(`/template/repo`);
	}

	uploadImg(data) {
		return axios.post(`/template/repo`, data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	createTemplate(body) {
		return axios.post(`/template/`, body);
	}

	copyTemplate(templateId, body) {
		return axios.post(`/template/${templateId}/copy/`, body);
	}

	deleteTemplate(templateId) {
		return axios.delete(`/template/${templateId}`);
	}

	createTemplateContent(templateId, body) {
		return axios.post(`/template/${templateId}/content`, body);
	}

	deleteTemplateContentLanguage(templateId, language) {
		return axios.delete(`/template/${templateId}/content/${language}`);
	}

	updateTemplateContent(templateId, language, body) {
		return axios.put(`/template/${templateId}/content/${language}`, body);
	}

	updateTemplate(templateId, body) {
		return axios.put(`/template/${templateId}`, body);
	}

	searchAllTemplates(searchText, scope, companyId) {
		let opt = { params: { searchText: searchText, scope: scope, sortBy: 'name', sortDesc: false, companyId: companyId } };
		return axios.get(`/template`, opt);
	}

	changeDeletedStatus(templateId, status) {
		return axios.put(`/template/${templateId}/status`, {deleted: status});
	}

	alignRankings() {
		return axios.get(`/template/align`);
	}

	importTemplate(data) {
		return axios.post('/template/import', data, {
			headers: {
				'Content-Type': 'multipart/form-data;'
			}
		});
	}

	exportTemplates() {
		return axios.get('/template/export');
	}

	exportTemplate(templateId) {
		return axios.get(`/template/export/${templateId}`);
	}

	testAttack(templateId, targetEmail, targetName, targetNumber, templateType, previewLanguage, companyId, singleLanguage) {
		let data = { target_email: targetEmail, target_name: targetName, target_number: targetNumber, 
			template_type: templateType, language: previewLanguage, company_id: companyId, single_language: singleLanguage };
		return axios.post(`/template/${templateId}/test`, data);
	}

}

export default new TemplateService();
