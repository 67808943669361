<template>
    <transition name="slide-fade">

        <!-- Template Preview -->
        <div v-if="$checkPermission('view-template')">

            <!-- Test Template Attack Modal -->
            <b-modal :id="'test-template-attack-' + templateName" content-class="shadow" size='md' buttonSize='md' okVariant='primary' 
                bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Campaigns.TestTemplate')" @ok="testAttack"
                @show="(templateType!=TEMPLATE_TYPE_SMS? targetEmail = $account.email : targetEmail = 'noreply@cgphishing.com'); targetName = $account.displayname" 
                :okDisabled="(templateType!=TEMPLATE_TYPE_SMS && !targetEmail) || (templateType==TEMPLATE_TYPE_SMS && !targetNumber) || !isValidDomain">

                <p class="text-left" v-html="$t('Campaigns.TestAttackMessage')"></p>
                <b-form-input size="sm" class="w-75 mt-4" style="margin: 0 auto;" :placeholder="$t('General.Name')" v-model="targetName"></b-form-input>
                <b-form-input size="sm" class="w-75 mt-4" style="margin: 0 auto;" :placeholder="$t('General.Number')" v-if="templateType==TEMPLATE_TYPE_SMS" 
                    v-model="targetNumber"></b-form-input>
                <b-form-input size="sm" id="email" class="w-75 mt-4" :class="{'form-field-error' : !isValidDomain}" style="margin: 0 auto;" :placeholder="$t('General.Email')" v-else v-model="targetEmail"></b-form-input>
                <b-tooltip target="email" v-if="!isValidDomain">{{ $tc('General.InvalidDomain', validDomain.count, {'param': validDomain.domain}) }}</b-tooltip>
            </b-modal>

            <!-- Modal to upload new/copy already uploaded image  -->
            <b-modal id="uploadImage" @ok="copyTemplateTag" size="lg" :title="$t('Template.UploadImgLabel')" :ok-title="$t('General.Copy')">
				
				<cg-localized-alert :alertVariant="alertVariant" ref="templateImageAlert" :alertMessage="alertMessage" 
                    :alertParams="alertParams"></cg-localized-alert>

                <b-row align-h="end">
                    <b-col>
                        <b-table
                            id="templateImgTable"
                            ref="templateImgTable"
                            :items="repo"
                            :fields="imageRepoFields"
                            :sort-by.sync="imageRepoSortBy"
                            :sort-desc.sync="imageRepoSortDesc"
                            sticky-header="444px"
                            selectable
                            select-mode="single"
                            @row-selected="selectRow"
                            hover striped show-empty no-border-collapse no-sort-reset
                            :emptyFilteredText="$t('General.TableFilterEmpty')"
                            :empty-text="$t('General.TableEmpty')">
                            
                            <template v-slot:cell(icon)="row">
                                <img :src="`${row.item.icon}`" alt="" border="0" height="30" width="30">
                            </template>

                            <template #cell(name)="row">
                                <span :id="row.item.name">{{ row.item.name }}</span>
                            </template>
                        </b-table>
                        <b-form-file v-model="newImg" size="md" class="w-50 float-right" :placeholder="$t('Template.UploadImg')" accept="image/*" id="imgFile" ref="imgFile" @input="checkDuplicates"></b-form-file>
                    </b-col>
                </b-row>
            </b-modal>

            <!-- Modal to prevent replacing images with the same name by error -->
            <b-modal id="irreversibleOverrideImage" content-class="shadow" size='md' buttonSize='md' okVariant='danger' hide-header hide-footer
                bodyClass="text-center" footerClass='p-2' :centered='true'>
                <h3>{{ $t('Template.ConfirmOverrideImageWarning') }}</h3>
                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="my-3 exclamation-triangle"/>
                <p style="font-bold">{{ $t("Template.ConfirmOverrideImage") }}</p>
                <b-row align-h="between" class="cg-modal-footer mt-4 pt-3 pb-1">
                    <b-col>
                        <b-button @click="$bvModal.hide('irreversibleOverrideImage');newImg=null;">{{ $t('General.Cancel') }}</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="danger" @click="$bvModal.hide('irreversibleOverrideImage');uploadImg()">{{ $t('General.Override') }}</b-button>
                    </b-col>
                </b-row>
            </b-modal>

            <!-- Modal for unsaved info -->
            <b-modal id="unsavedTemplateInfo" content-class="shadow" size='md' buttonSize='md' okVariant='danger'
                bodyClass="text-center" footerClass="p-2" :centered='true' @ok="undoUnsavedChanges" :title="$t('General.UnsavedInfo')">
                <p class="my-2 text-left">{{ $t('General.UnsavedInfoText') }}</p>
            </b-modal>
                
            <!-- Template Preview Modal -->
            <b-modal :id="'template-modal' + templateId" size="xl" ref="templateModal" body-class="px-0 py-0 modal-body-full-width template" @hide="manageUnsavedInfo" @close="handleClose">
                
                <div slot="modal-title">
                    <font-awesome-icon :icon="getTemplateTypeIcon(templateType)" class="mr-2" />{{ templateName }}
                </div>

                <cg-localized-alert :alertVariant="alertVariant" ref="templateAlert" :alertMessage="alertMessage" 
                    :alertParams="alertParams" :dismissSecs="dismissSecs"></cg-localized-alert>

                <div v-if="template && isCampaign" class="mb-4 mt-2 mx-4">
                    <b-form-group label-cols-lg="12" :label="$t('Template.Preview')" label-size="lg" label-class="label-profile pt-0" class="mb-0">
                        <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="languageTemplateSelect" :label="$t('Campaigns.AvailableLanguages')"
                            label-align-sm="right" :class="{ 'mx-1' : templateType==TEMPLATE_TYPE_SMS }">
                            <b-form-select id="languageTemplateSelect" size="sm" v-model="previewLanguage" @change="reloadPreview" 
                                :options="langOptions">
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            v-if="templateType!=TEMPLATE_TYPE_SMS"
                            class="form-align-self-center"
                            label-cols-sm="3"
                            label-for="templateSubject"
                            :label="$t('Campaigns.Subject')"
                            label-align-sm="right"
                        >
                            <b-form-input size="sm" id="templateSubject" readonly class="no-bg" v-model="emailSubject"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="templateType!=TEMPLATE_TYPE_SMS"
                            class="form-align-self-center"
                            label-cols-sm="3"
                            label-for="templateSender"
                            :label="$t('Campaigns.Sender')"
                            label-align-sm="right"
                        >
                            <b-form-input size="sm" id="templateSender" readonly class="no-bg" v-model="emailSender" type="email" ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="templateType==TEMPLATE_TYPE_SMS"
                            class="form-align-self-center"
                            label-cols-sm="3"
                            label-for="smsSender"
                            :label="$t('Campaigns.Sender')"
                            label-align-sm="right"
                        >
                            <b-form-input size="sm" id="smsSender" readonly class="no-bg" v-model="smsSender"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="templateType!=TEMPLATE_TYPE_SMS && templateAttachmentId"
                            class="form-align-self-center"
                            label-cols-sm="3"
                            label-for="templateAttachmentId"
                            :label="$t('Template.AttachmentId')"
                            label-align-sm="right"
                        >
                            <b-row>
                                <b-col class="pr-3">
                                    <b-form-select size="sm" id="attachmentId" disabled v-model="templateAttachmentId" :options="attachmentOptions">
                                    </b-form-select>
                                </b-col>
                                <b-col cols="auto" class="pr-3">
                                    <b-button size="sm" variant="primary" class="btn-action my-1 mx-0" :disabled="!currentTemplate.attachment_id" @click="downloadAttachment"><font-awesome-icon class="mx-2" :icon="['fas', 'download']"></font-awesome-icon>{{ $t('Targets.Download') }}</b-button>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-form-group>

                    <!-- Template Preview/Editor -->
                    <div class="preview-template" :class="{ 'sms-type' : templateType==TEMPLATE_TYPE_SMS }">

                        <!-- Template Preview/Editor for EMAIL Templates -->
                        <div v-if="templateType!=TEMPLATE_TYPE_SMS" class="h-100">
                            <iframe id="templatePreviewEmailIframe" :srcdoc="previewLg" @load="emailIFrameLoaded" v-if="previewLg" frameborder="0" width="100%"></iframe>
                        </div>

                        <!-- Template Preview/Editor for SMS Templates -->
                        <div v-if="templateType==TEMPLATE_TYPE_SMS" class="h-100">
                            <b-form-group class="mt-2 mb-0">
                                <div class="sms-preview full-body mt-2" v-if="codePreview && previewLg">
                                    <iframe id="templatePreviewIframe" :srcdoc="previewLg" @load="smsIFrameLoaded" class="px-4 py-4" frameborder="0" width="100%"></iframe>
                                </div>
                                <div v-else class="editor-container">
                                    <b-alert show>{{ $t('Template.InfoCreateContentEditor') }}</b-alert>
                                </div>
                            </b-form-group>
                        </div>
                    </div>

					<b-button variant="primary" v-if="$checkPermission('test-template')" class="btn-action float-right w-25 my-3" 
						@click="targetEmailModal()">{{ $t('Campaigns.TestTemplate') }}</b-button>

                </div>

                <b-tabs card v-if="template && !isCampaign" @activate-tab="changeTab">

                    <!-- Template Preview and Editor tab -->
                    <b-tab :title="$t('Template.Preview')" active :disabled="noLanguageAvailable">

                        <b-form-group label-cols-lg="12" :label="$t('Template.Header')" label-size="lg" label-class="label-profile pt-0" class="mb-0">
                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="languageTemplateSelect" :label="$t('Campaigns.AvailableLanguages')"
                                label-align-sm="right" :class="{ 'mx-1' : templateType==TEMPLATE_TYPE_SMS }">
                                <b-form-select id="languageTemplateSelect" size="sm" v-model="previewLanguage" @change="reloadPreview" 
                                    :options="langOptions"><!-- TODO: changeEditPreviewLanguage-->
                                </b-form-select>
                            </b-form-group>

                            <b-form-group
                                v-if="templateType!=TEMPLATE_TYPE_SMS"
                                class="form-align-self-center"
                                label-cols-sm="3"
                                label-for="templateSubject"
                                :label="$t('Campaigns.Subject')"
                                label-align-sm="right"
                            >
                                <b-form-input size="sm" id="templateSubject" v-model="emailSubject" @change="checkSubjectPlaceholder" @input="updateTemplateMetadata"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                v-if="templateType!=TEMPLATE_TYPE_SMS"
                                class="form-align-self-center"
                                label-cols-sm="3"
                                label-for="templateSender"
                                :label="$t('Campaigns.Sender')"
                                label-align-sm="right"
                            >
                                <b-form-input size="sm" id="templateSender" v-model="emailSender" type="email" @input="updateTemplateMetadata"></b-form-input>
                            </b-form-group>

                            <b-form-group
                                v-if="templateType==TEMPLATE_TYPE_SMS"
                                class="form-align-self-center"
                                label-cols-sm="3"
                                label-for="smsSender"
                                :label="$t('Campaigns.Sender')"
                                label-align-sm="right"
                            >
                                <b-form-input size="sm" id="smsSender" v-model="smsSender" :class="{'form-field-error': isUnvalidSender}" class="form-control"
									@change="checkSMSSender" @input="updateTemplateMetadata"></b-form-input>
                            </b-form-group>
                        </b-form-group>

                        <div class="separator mb-3"></div>

                        <!-- Show Preview/Editor toggle button -->
                        <b-row align-h="end">
                            <b-col>
                                <b-form-group class="form-align-self-center" v-if="showEditor && templateType!=TEMPLATE_TYPE_SMS">
                                    <b-form-select id="tagTemplateSelect" size="sm" v-model="selectedTag" class="w-50 mr-2" :options="tagOptions">
                                    </b-form-select>
									<b-button @click="copyTemplateTag" :disabled="!selectedTag" size="sm" variant="primary">{{ $t('General.Copy' )}}</b-button>
									<b-button v-b-modal.uploadImage size="sm" class="ml-2" variant="primary">
                                        <font-awesome-icon :icon="['fas', 'image']" class="mr-2" />{{ $t('Template.UploadImgLabel') }}
                                    </b-button>
                                </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                                <b-button v-if="$checkPermission('modify-template') && templateType!=TEMPLATE_TYPE_SMS" @click="showEditor = !showEditor" size="sm" variant="primary" class="mb-3">
                                    <font-awesome-icon v-if="showEditor" class="vertical-align-middle mr-2" :icon="['fas', 'eye']"/>
                                    <font-awesome-icon v-else class="vertical-align-middle mr-2" :icon="['fas', 'edit']"/>
                                    {{ showEditor? $t('Template.ShowPreview') : $t('Template.ShowEditor') }}
                                </b-button>
                                <b-button v-if="$checkPermission('modify-template') && templateType!=TEMPLATE_TYPE_SMS" size="sm" variant="primary" class="ml-2 mb-3" :disabled="disableSaveBodyButton"
                                    @click="saveTemplate">{{ $t('General.Save') }}</b-button>
                            </b-col>
                        </b-row>

                        <!-- Template Preview/Editor -->
                        <div class="preview-template" :class="{ 'sms-type' : templateType == TEMPLATE_TYPE_SMS }">

                            <!-- Template Preview/Editor for EMAIL Templates -->
                            <div v-if="templateType!=TEMPLATE_TYPE_SMS" class="h-100">
                                <prism-editor v-if="showEditor" :code="codePreview" :lineNumbers="true" @change="checkBodyPlaceholder">
                                </prism-editor>
                                <iframe id="templatePreviewEmailIframe" :srcdoc="previewLg" @load="emailIFrameLoaded" v-if="previewLg && !showEditor" frameborder="0" width="100%"></iframe>
                            </div>

                            <!-- Template Preview/Editor for SMS Templates -->
                            <div v-if="templateType==TEMPLATE_TYPE_SMS" class="h-100">
                                <b-form-group label-cols-lg="12" :label="$t('Template.SMSTemplateCode')" label-for="templateCode" label-size="lg" label-class="label-profile pt-0" class="mt-2 mb-0">
									<b-form-group class="form-align-self-center">
										<b-form-select id="tagTemplateSelect" size="sm" v-model="selectedTag" class="w-50 mr-2" :options="tagOptions">
										</b-form-select>
										<b-button @click="copyTemplateTag" :disabled="!selectedTag" size="sm" variant="primary">{{ $t('General.Copy' )}}</b-button>
                                        <b-button v-if="$checkPermission('modify-template') && templateType==TEMPLATE_TYPE_SMS" size="sm" variant="primary" class="float-right ml-2" :disabled="disableSaveSMSBodyButton" @click="saveTemplate">{{ $t('General.Save') }}</b-button>
									</b-form-group>
                                    
                                    <b-form-textarea id="templateCode" v-model="codePreview" no-auto-shrink rows="5" class="mb-2" @input="checkBodyPlaceholder"></b-form-textarea>
                                    <div class="font-italic font-14 mx-4 mb-4">{{ $t('Template.SMSTemplateHint') }}</div>
                                </b-form-group>
                                <b-form-group label-cols-lg="12" :label="$t('Template.Preview')" label-size="lg" label-class="label-profile pt-0" class="mt-2 mb-0">
                                    <div class="sms-preview mt-2" v-if="codePreview && previewLg">
                                        <iframe id="templatePreviewIframe" :srcdoc="previewLg" @load="smsIFrameLoaded" v-show="isLoaded" class="px-4 py-4" frameborder="0" width="100%"></iframe>
                                        <b-spinner class="spinner sms-preview-spinner" variant="primary" :label="$t('General.Loading')" v-show="!isLoaded"></b-spinner>
                                    </div>
                                    <div v-else class="editor-container">
                                        <b-alert show>{{$t('Template.InfoCreateContentEditor')}}</b-alert>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>

                    <!-- Template Basic Info tab -->
                    <b-tab v-if="$checkPermission('modify-template')" class="mx-3" :title="$t('Template.BasicInfo')">

                        <b-form-group label-cols-lg="12" :label="$t('LandingPage.BasicInfo')" label-size="lg" label-class="label-profile pt-0" class="mb-0">
                            
                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="templateName" :label="$t('Template.Name')" label-align-sm="right">
                                <b-form-input size="sm" id="templateName" v-model="currentTemplate.name"></b-form-input>
                            </b-form-group>

                            <b-form-group v-if="$checkPermission('view-landing-page')" class="form-align-self-center" label-cols-sm="3" label-for="landingPageId" :label="$t('Template.LandingPageId')" label-align-sm="right">
                                <b-form-select size="sm" id="landingPageId" v-model="currentTemplate.landing_page_id" :options="landingpageIdOptions"></b-form-select>
                            </b-form-group>

                            <b-form-group v-if="$checkPermission('view-landing-page')" class="form-align-self-center" label-cols-sm="3" label-for="landingPage" :label="$t('Template.LandingPage')" label-align-sm="right">
                                <b-form-input size="sm" id="landingPage" v-model="currentTemplate.landing_page_url"></b-form-input>
                            </b-form-group>

                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="tags" :label="$t('Template.Tags')" label-align-sm="right">
                                <b-form-textarea size="sm" rows="5" id="tags" v-model="currentTemplate.tags"></b-form-textarea>
                            </b-form-group>

                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="difficulty" :label="$t('Template.Difficulty')" label-align-sm="right">
                                <b-form-input size="sm" id="difficulty" v-model="currentTemplate.difficulty"></b-form-input>
                            </b-form-group>

                            <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="type" :label="$t('Template.Type')" label-align-sm="right">
                                <b-form-select ref="type" size="sm" id="templateStatus" disabled v-model="currentTemplate.type" :options="typeOptions">
								</b-form-select>
                            </b-form-group>

                            <b-form-group class="form-align-self-center" v-if="currentTemplate.type!==TEMPLATE_TYPE_SMS" label-cols-sm="3" label-for="attachment" :label="$t('Template.HasAttachment')" label-align-sm="right">
                                <b-form-checkbox ref="attachment" class="btn-status float-left align-left" switch size="lg" button-variant="info" 
									v-model="currentTemplate.has_attachment" :value="1" :unchecked-value="0">
                                    <span class="sr-only">{{ $t('Template.HasAttachment') }}</span>
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group class="form-align-self-center" v-if="currentTemplate.type!==TEMPLATE_TYPE_SMS" label-cols-sm="3" label-for="attachmentId" :label="$t('Template.AttachmentId')" label-align-sm="right">
                                <b-row>
                                    <b-col class="pr-3">
                                        <b-form-select size="sm" id="attachmentId" :disabled="!currentTemplate.has_attachment" v-model="currentTemplate.attachment_id" :options="attachmentOptions">
                                        </b-form-select>
                                    </b-col>
                                    <b-col cols="auto" class="pr-3">
                                        <b-button size="sm" variant="primary" class="btn-action my-1 mx-0" :disabled="!currentTemplate.attachment_id" @click="downloadAttachment"><font-awesome-icon class="mx-2" :icon="['fas', 'download']"></font-awesome-icon>{{ $t('Targets.Download') }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>

							<b-form-group class="form-align-self-center" label-cols-sm="3" label-for="delete" :label="$t('Template.IsDeleted')" label-align-sm="right">
								<b-form-checkbox ref="delete" class="btn-status float-left align-left" switch size="lg" button-variant="info" 
									v-model="currentTemplate.deleted" :value="null" unchecked-value="1" @change="disableForced">
                                    <span class="sr-only">{{ $t('Template.IsDeleted') }}</span>
								</b-form-checkbox>
							</b-form-group>
                            
							<b-form-group class="form-align-self-center" label-cols-sm="3" label-for="forced" :label="$t('Template.IsForced')" label-align-sm="right">
								<b-form-checkbox ref="forced" class="btn-status float-left align-left" switch size="lg" button-variant="info" 
									v-model="currentTemplate.forced" :value="1" :unchecked-value="null" :disabled="currentTemplate.deleted==1">
                                    <span class="sr-only">{{ $t('Template.IsForced') }}</span>
								</b-form-checkbox>
							</b-form-group>
                        </b-form-group>

						<b-row align-h="end">
							<b-col cols="auto" class="pr-3">
								<b-button variant="primary" class="btn-action my-1 mx-0" :disabled="disableSaveInfoButton" @click="updateTemplateBasicInfo">{{ $t('General.Save') }}</b-button>
							</b-col>
						</b-row>
                    </b-tab>

                    <!-- Template Languages tab -->
                    <b-tab v-if="$checkPermission('modify-template')" class="mx-3" :title="$t('Template.Langs')">

                        <b-table
                            id="languageListTable"
                            ref="languageTable"
                            :items="langOptions"
                            :fields="languageTable.fields"
                            :sort-by.sync="languageTable.sortBy"
                            :sort-desc.sync="languageTable.sortDesc"
                            :per-page="languageTable.perPage"
                            :current-page="languageTable.currentPage"
                            striped hover 
                            show-empty 
                            no-sort-reset 
                            sticky-header
                            no-border-collapse
                            :emptyFilteredText="$t('General.TableFilterEmpty')"
                            :empty-text="$t('General.TableEmpty')"
                        >
                            <template v-slot:cell(actions)="row">
                                <div class="table-row-center">
                                    <div class="action delete">
                                        <font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
                                            @click="confirmDeleteTemplateLanguage(row.item.value)"/>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:cell(language)="row">
                                <div>{{ row.item.text }}</div>
                            </template>
                        </b-table>

                        <div class="separator mb-4"></div>

                        <b-form-group label-cols-lg="12" :label="$t('Template.AddContent')" label-size="lg" label-class="label-profile pt-0">
                            <!-- New Content for language -->
                            <div class="mt-4 pt-4 mx-3">
                                <b-card-text class="mb-4">{{ $t('Template.NewTemplateLanguage') }}</b-card-text>
                                <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="languageNewTemplateSelect" :label="$t('Campaigns.AvailableLanguages')"
                                    label-align-sm="right">
                                    <b-form-select id="languageNewTemplateSelect" v-model="newTemplateLanguage" :options="$langs"></b-form-select>
                                </b-form-group>

                                <b-row align-h="end">
                                    <b-col cols="auto" class="pr-3">
                                        <b-button variant="primary" class="btn-action my-1 mx-0" :disabled="!newTemplateLanguage" @click="addTemplateLanguage()">
                                            {{ $t('Template.AddTemplateContent') }}</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form-group>

                        <div class="separator mb-4"></div>
                          <!-- New Country for language -->
                        <b-form-group label-cols-lg="12" :label="$t('Template.AddCountry')" label-size="lg" label-class="label-profile pt-0">
                            <div class="mt-4 pt-4 mx-3">
                                <b-card-text class="mb-4">{{ $t('Template.NewTemplateCountry') }}</b-card-text>
                                <b-form-group class="form-align-self-center" label-cols-sm="3" label-for="inputTagsLanguage" :label="$tc('General.Country', 2)"
                                    label-align-sm="right">
                                     <tags-input element-id="inputTagsLanguage"
                                        :existing-tags="countryList"
                                        v-model="parseCountries"
                                        :typeahead="true"
                                        typeahead-style="dropdown"
                                        :only-existing-tags="true"
                                        :delete-on-backspace="true"
                                        @change="addTag"
                                        @tag-removed="removeTag"
                                        :placeholder="tagInputPlaceholder"
                                        :typeahead-hide-discard="true">

                                        <template v-slot:selected-tag="{ tag, index, removeTag }">
                                            <span v-html="tag.value"></span>
                                            <a href="#" :aria-label="$t('General.Delete')" class="tags-input-remove" @click.prevent="removeTag(index)"></a>
                                        </template>

                                    </tags-input>
                                </b-form-group>

                                <b-row align-h="end">
                                    <b-col cols="auto" class="pr-3">
                                        <b-button variant="primary" class="btn-action my-1 mx-0" :disabled="disableSaveCountryButton" @click="updateTemplateBasicInfo">
                                            {{ $t('General.Save') }}</b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-form-group>
                    </b-tab>

                    <!-- Template Feature tab -->
                    <b-tab v-if="($checkPermission('export-template') || ($checkPermission('test-template') && companyData.cgsmtp))" :disabled="noLanguageAvailable" id="template-feature" 
                        class="mx-3" :title="$t('Template.Features')">

                        <b-alert id="notification" :show="preventClosingModal" variant="warning" dismissible><span>{{ $t('Template.SendMessage') }}</span></b-alert>

                        <b-form-group label-cols-lg="12" :label="$t('Template.DownloadTemplateXML')" label-size="lg" label-class="pt-0">
                            <b-form-group class="form-align-self-center" label-cols-sm="9" v-if="$checkPermission('export-template')" 
                                label-for="exportXMLTemplate" :label="$t('Template.ExportXMLLabel')" label-align-sm="right">
                                <b-button variant="primary" id="exportXMLTemplate" class="btn-action w-100" :href="'api/template/export/'+templateId">
                                    {{ $t("Template.ExportXML") }}</b-button>
                            </b-form-group>
                        </b-form-group>

                        <div class="separator mb-4"></div>

                        <b-form-group label-cols-lg="12" :label="$t('Template.SingleFeatureCheck')" label-size="lg" label-class="pt-0">

                            <b-form-group class="form-align-self-center" label-cols-sm="6" label-for="languageTemplateSelectTest" :label="$t('Template.AvailableLanguagesFeatures')"
                                label-align-sm="right">
                                <b-form-select id="languageTemplateSelectTest" size="sm" v-model="previewLanguage" @change="reloadPreview" :options="langOptions">
                                </b-form-select>
                            </b-form-group>
                            
                            <b-form-group class="form-align-self-center mt-4" label-cols-sm="9" v-if="$checkPermission('export-template')" 
                                label-for="exportHTMLTemplate" :label="$t('Template.ExportHTMLLabel')" label-align-sm="right">
                                <b-button variant="primary" id="exportHTMLTemplate" class="btn-action w-100" href="#" @click="downloadTemplateHTML()">{{ $t('Template.ExportHTML') }}</b-button>
                            </b-form-group>

                            <b-form-group class="form-align-self-center mt-4" label-cols-sm="9" v-if="$checkPermission('test-template')" 
                                label-for="testTemplateButton" :label="$t('Template.TestTemplateLabel')" label-align-sm="right">
                                <b-button variant="primary" id="testTemplateButton" class="btn-action w-100" href="#" @click="targetEmailModal(false)">{{ $t('Campaigns.TestTemplate') }}</b-button>
                            </b-form-group>
                            
                            <b-form-group class="form-align-self-center mt-4" label-cols-sm="9" v-if="$checkPermission('list-company-all,test-template')" 
                                label-for="adminTestTemplateButton" :label="$t('Template.AdminTestTemplateLabel')" label-align-sm="right">
                                <b-button variant="primary" id="adminTestTemplateButton" class="btn-action w-100" href="#" @click="targetEmailModal(true)">{{ $t('Campaigns.TestTemplate') }} ({{ previewLanguage && previewLanguage.toUpperCase() }})</b-button>
                            </b-form-group>
                        </b-form-group>

                        <div class="separator mb-4"></div>

                        <b-form-group v-if="$checkPermission('modify-template') && companyOptions.length >= 1" label-cols-lg="12" :label="$t('Template.CopyTemplate')" label-size="lg" label-class="label-profile pt-0" >
                            <b-row id="copyTemplateCompanySelect" class="mt-2">
                                <b-col class="col-sm-9">
                                    <b-form-select id="copyTemplateCompanySelect" v-model="templateCompany" :options="companyOptions"></b-form-select>
                                </b-col>
                                <b-col class="col-sm-3 pl-0">
                                    <b-button variant="primary" id="copyTemplateButton" class="btn-action w-100" @click="copyTemplate">{{ $t('General.Copy') }}</b-button>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-tab>

                    <!-- Template Hints tab -->
                    <b-tab v-if="$checkPermission('view-template-hint')" id="template-hint" class="mt-4 mx-3" :disabled="noLanguageAvailable" :title="$t('Template.Hints')">
                        <b-form-group
                            class="form-align-self-center"
                            label-cols-sm="3"
                            label-for="languageHintSelectEdit"
                            :label="$t('Campaigns.AvailableLanguages')"
                            label-align-sm="right"
                        >
                            <b-form-select
                                id="languageHintSelectEdit"
                                size="sm"
                                v-model="previewLanguage"
                                @change="loadHints();reloadPreview()" 
                                :options="langOptions"
                            ></b-form-select>
                        </b-form-group>
                        <cg-modal :data="hintData" @update="updateHint"></cg-modal>

                        <b-table
                            id="hintListTable"
                            ref="hintTable"
                            striped hover 
                            :items="getCurrentHintPage"
                            :per-page="hintTable.perPage"
                            :current-page="hintTable.currentPage"
                            :fields="hintTable.fields"
                            :sort-by.sync="hintTable.sortBy"
                            :sort-desc.sync="hintTable.sortDesc"
                            :filter="hintTable.filter"
                            show-empty bordered no-sort-reset
                            :emptyFilteredText="$t('General.TableFilterEmpty')"
                            :empty-text="$t('General.TableEmpty')"
                        >
                            <template v-if="$checkPermission('modify-template-hint') || $checkPermission('delete-template-hint')" v-slot:cell(actions)="row">
                                <div class="table-row-center">
                                    <div v-if="$checkPermission('delete-template-hint')" class="action delete">
                                        <font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
                                            @click="confirmDeleteHint(row.index, row.item)"/>
                                    </div>
                                    <div v-if="$checkPermission('modify-template-hint')" class="action edit">
                                        <font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
                                            @click="editHint(row.index, row.item)"/>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:cell(icon)="row">
                                <div class="table-row-center">
                                    {{ row.index }} {{ row.item }}
                                </div>
                            </template>
                        </b-table>

                        <!-- Pagination widget -->
                        <b-pagination v-model="hintTable.currentPage" :total-rows="hintTable.totalRows" :per-page="hintTable.perPage" align="right"
                            aria-controls="hintListTable"></b-pagination>

                        <b-button variant="primary" v-if="$checkPermission('create-template-hint')" 
                            class="btn-action float-right mb-3" @click="showModalCreateHint()">{{ $t('Template.NewHint') }}</b-button>
                    </b-tab>
                </b-tabs>

                <template v-slot:modal-footer>
                    <b-row class="template-card-modal-footer">
                        <b-col class="col">
                            <span v-html="supportMessage"></span>
                        </b-col>
                    </b-row>
                </template>
            </b-modal>

        </div>
    </transition>
</template>

<script>
import { TemplateType, TemplateScope, LandingPageScope, QRCode } from "../../../server/common/constants";
import templateService from "../services/template.service";
import landingpageService from "../services/landingpage.service";
import companyService from "../services/company.service";
import syspropService from '../services/sysprop.service';
import sanitizeHtml from 'sanitize-html'
import Vue from 'vue';

export default {
    name: "cg-template-modal",
    data: function() {
        return {
            loading: false,
            previewLg: null,
            codePreview: null,
            isLoaded: true,
            showEditor: false,
            alertVariant: 'danger',
            alertMessage: '',
            alertParams: {},
            toastTitle: '',
            previewLanguage: null,
            langOptions: [],
            tagOptions: [],
            landingpageIdOptions: [],
            typeOptions: [],
            attachmentOptions: [],
            copiedLabel: false,
            selectedTag: null,
            supportMessage: '',
            targetNumber: null,
            targetEmail: null,
            targetName: null,
            templateContent: null,
			currentTemplateContent: null,
            templateContentType: this.TEMPLATE_TYPE_EMAIL,
			newTemplateLanguage: null,
            companyOptions: [],
            templateCompany: null,
            companyList: [],
			currentTemplate: {
				name: null,
				tags: null,
                type: null,
                has_attachment: null,
                attachment_id: null,
                scope: null,
                page_id: null,
                landing_page_url: null,
                landing_page_id: null,
                difficulty: null,
                company_id: null,
                deleted: null,
				forced: null,
                country: null
            },
            newImg: null,
            imageRepoFields: [],
            imageRepoSortBy: "name",
			imageRepoSortDesc: false,
            attrList: [],
            emailSender: '',
            smsSender: '',
            emailSubject: '',
            landingPageURL: null,
            landingPageId: null,
            templateHints: [],
            languageTable: {
                perPage: null, 
                currentPage: null,
                sortBy: 'language',
                sortDesc: false,
                totalRows: 0,
                fields: [
                    { key: "language", label: this.$i18n.t('Template.Language'), sortable: true },
                    { key: "actions", label: this.$i18n.t('General.Actions'), sortable: false, thClass: "action-column" },
                ]
            },
            hintTable: {
                perPage: 10,
                currentPage: 1,
                sortBy: 'priority',
                sortDesc: false,
                totalRows: 0,
                filter: '',
                fields: [
                    { key: "hint_id", label: this.$i18n.t('Template.HintID'), sortable: true },
                    { key: "class", label: this.$i18n.t('Template.HintClass'), sortable: true },
                    { key: "priority", label: this.$i18n.t('Template.HintPriority'), sortable: true },
                    { key: "body", label: this.$i18n.t('Template.HintBody'), sortable: true },
                    { key: "actions", label: this.$i18n.t('General.Actions'), sortable: false },
                ]
            },
            hintData: {
				name: "hint-modal",
				item: {},
				submitHandler: "updateHint",
				fields:[
                    {   id: "hint_id",
						label: 'Template.HintID',
						type: "text",
                        display: true,
						required: false,
						disabled: true,
						placeholder: 'Template.HintID'
                    },
					{   id: "class",
						label: 'Template.HintClass',
						type: "select",
                        display: true,
                        options: [],
                        required: true,
						placeholder: 'Template.HintClass'
                    },
					{   id: "priority",
						label: 'Template.HintPriority',
						type: "number",
                        display: true,
                        max: 2147483647,
						placeholder: 'Template.HintPriority'
					},
					{   id: "language",
						label: 'Template.Language',
                        type: "select",
                        required: true,
						options: [],
						display: true,
                        placeholder: 'Template.Language'
					},
					{   id: "icon",
						label: 'Template.HintIcon',
                        type: "icon-selector",
                        required: false,
						options: [],
						display: true,
                        placeholder: 'Template.HintIcon'
					},
					{   id: "body",
						label: 'Template.HintBody',
                        type: "textarea",
                        required: true,
						display: true,
						rows: 15,
						lineheight: 4,
						placeholder: 'Template.HintBody'
                    }
                ]
            },
            sanitizeOption: {
                allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'html', 'body', 'header', 'img', 'span', 'h1', 'h2', 'blockquote', 's', 'u', 'font', 'input', 'button']),
                allowedAttributes: {
                    '*': ['style', 'class', 'alt', 'src', 'title', 'color'],
                    'a': ['href', 'class', 'target'],
                    'input': ['type'],
                    'img': ['src', 'alt']
                },
                transformTags: {
                    'a': sanitizeHtml.simpleTransform('a', { class: 'landing-link' })
                },
                allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat([ 'data'])
            },
            isoCountries: null,
            countryList: null,
            parseCountries: null,
            selectOptionString: this.$i18n.t('Template.AllLanguages'),
            tagInputPlaceholder: null,
            emailDomain: null,
            validDomain: {},
            preventClosingModal: false,
            dismissSecs: 5
        };
    },
    props: ["template", "company-data", "is-campaign", "repo"],
    computed: {
        templateId() {
            return this.template && this.template.template_id;
        },
        templateName() {
            return this.template && this.template.name;
        },
        templateType() {
            return this.template && this.template.type;
        },
        templateAttachmentId() {
            return this.template && this.template.attachment_id;
        },
        disableSaveInfoButton() {
            let status = true;

            if(!this.currentTemplate || !this.template) {
                status = true;
            }
            this.attrList.forEach(name => {
                if(this.currentTemplate[name] !== this.template[name]) {
                    status = false;
                }
            });
            return status;
        },
        disableSaveBodyButton() {
			if(!this.templateContent || !this.currentTemplateContent || !this.emailSubject || !this.emailSender) {
				return true;
            }
            let savedData = this.templateContent.find(template => { return template.language == this.previewLanguage });
            let previousData = this.currentTemplateContent.find(template => { return template.language == this.previewLanguage });

            return JSON.stringify(previousData) === JSON.stringify(savedData);
		},
        disableSaveSMSBodyButton() {
			if(!this.templateContent || !this.currentTemplateContent) {
				return true;
            }

            if(this.smsSender && this.smsSender.length > 11) {
                // SMS sender field must contain 11 characters at most
                return true;
            }
            let savedData = this.templateContent.find(template => { return template.language == this.previewLanguage });
            let previousData = this.currentTemplateContent.find(template => { return template.language == this.previewLanguage });

            return JSON.stringify(previousData) === JSON.stringify(savedData);
		},
        disableSaveCountryButton() {
            let origin = this.currentTemplate && this.currentTemplate.country;
            let current = this.parseCountries && this.parseCountries.map(c => c.key).join('#') || null;
            return current == origin;
        },
        noLanguageAvailable() {
            return this.langOptions && this.langOptions.length == 0;
        },
        isOnlyLanguage() {
            return this.langOptions.length == 1;
        },
        isValidDomain() {
			if(!this.targetEmail) return;

			let trimmeredEmail = this.targetEmail.trim();

            return this.$checkPermission('list-company-all') ? true : 
				((trimmeredEmail && this.emailDomain && this.emailDomain.length > 0) ? this.emailDomain.includes(trimmeredEmail.substring(trimmeredEmail.indexOf('@'))) : true);  
		},
        isUnvalidSender() {
            if(!this.smsSender) return;

            var senderRegex = new RegExp(/[a-zA-Z0-9 .]{0,11}/);
            var sender = senderRegex.exec(this.smsSender);

            return this.smsSender.length > 11 || sender[0] !== this.smsSender;
        }
    },
    methods: {
        handleClose(event) {
            if(this.preventClosingModal) {
                event.preventDefault();
            }
        },
        async setLandingPageOptions() {
            let lands = (this.currentTemplate.scope == TemplateScope.Company) ?
                await landingpageService.getLandingPages(this.companyData.company_id, LandingPageScope.GlobalAndCompany):
                await landingpageService.getLandingPages(this.companyData.company_id, LandingPageScope.Global);

            // empty the array of landing pages options
            this.landingpageIdOptions = [];

            this.landingpageIdOptions.push({ value: null, text: this.$t('Company.DefaultLandingPagePlaceholder') });

            let orderedLands = lands.data.sort((a, b) => { return (a.scope > b.scope) || (a.page_id > b.page_id); });
            
            orderedLands.forEach(temp => {
                let lpname=(temp.scope == LandingPageScope.Global)? this.$t('Template.LandingPageNameGlobal', { id: temp.page_id, name: temp.name }): this.$t('Template.LandingPageName', { id: temp.page_id, name: temp.name });
				this.landingpageIdOptions.push( { value: temp.page_id, text: lpname } );
            });            
        },
        async downloadAttachment() {
            const id = this.currentTemplate.attachment_id;

            let attachment = this.attachmentList.data.filter(a => a.id === id);
            let hasPreviewLanguage = attachment.filter(a => a.language === this.previewLanguage);
            let hasEnglishLanguage = attachment.filter(a => a.language === 'en');
            let firstLanguage = attachment[0].language;

            const language = hasPreviewLanguage.length? hasPreviewLanguage[0].language : hasEnglishLanguage.length? hasEnglishLanguage[0].language : firstLanguage;

			console.debug("Downloading attachment", id, language);

			this.loading = true;

			try {
				const response = await fetch(`/api/template/attachments/content?id=${id}&language=${language}`);
				if (!response.ok) {
					throw new Error('Download failed');
				}
				
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = "Attachment_" + id + "_" + language;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (err) {
				console.error("Attachment - downloadTemplateAttachment error", err);

				this.alertMessage = 'Attachment.DownloadError';
				this.alertVariant = 'danger';
				this.$refs.templateAlert.showAlert();
			} finally {
				this.loading = false;
			}
		},
        async showPreviewModal(template) {
            this.getTemplateContent(template);
			this.showEditor = false;
			this.selectedTag = null;

            Vue.set(this.tagOptions.find(el => el.value === '{{.CompanyLogoAsDataURL}}'), 'disabled', template && template.type == this.TEMPLATE_TYPE_SMS);

            let tagQRCodeIndex = this.tagOptions.findIndex(el => el.value === '{{.QRCode}}');

            // QRCode tag MUST be hidden for SMS Templates and for non admin users whose Company hasn't enabled QRCode remediations
            if ((template && template.type != this.TEMPLATE_TYPE_SMS) && (this.$checkPermission('list-company-all') || this.companyData.company.enable_rem_qrcode)) {
                if(tagQRCodeIndex == -1) {
                    this.tagOptions.push({ text: this.$i18n.t('General.QRCode'), value: '{{.QRCode}}', disabled: false });
                }
            } else {
                if(tagQRCodeIndex != -1) {
                    this.tagOptions.splice(tagQRCodeIndex, 1);
                }
            }
			
			if(template && template.type == this.TEMPLATE_TYPE_SMS) {
				let previewBody = template.body.find(t => { return t.language == this.previewLanguage });

				var senderRegex = new RegExp(/<cgsender>(.*)<\/cgsender>/);
				var sender = previewBody ? senderRegex.exec(previewBody.body) : null;

				this.smsSender = sender ? sender[1] : '';
			}

            this.parseCountryList(template);

            this.tagInputPlaceholder = null;
            
            this.attrList.forEach(name => {
                console.debug(name + ': ', 'current => ', this.currentTemplate[name], 'template => ', template[name]);
                this.currentTemplate[name] = template[name];
            });

            this.supportMessage = this.companyData.contact? this.$t('Campaigns.ContactUsTemplate', { param: this.companyData.contact }) :
                this.$t('Campaigns.ContactSupportTemplate');

            // Exclude current company from list
            let filteredCompanyList = this.companyList.filter((c) => { return c.company_id !== template.company_id; });

            this.templateCompany = (filteredCompanyList.length>0)? filteredCompanyList[0].company_id: template;

            this.companyOptions = [];

            // Check if the logged user is admin
            if (this.$checkPermission('list-company-all') && (template.company_id)) {
                this.templateCompany = null;
                this.companyOptions.push( { value: null, text: this.$t('Template.Global') } );	
            }

            filteredCompanyList.forEach(c => {
                this.companyOptions.push( { value: c.company_id, text: c.name } );
            });


            // Check if the logged user is admin
            if (this.$checkPermission('view-landing-page')) {
               this.setLandingPageOptions();
            }

            // Retrieve attachments
            this.attachmentList = await templateService.getTemplateAttachments(template.company_id, TemplateScope.GlobalAndCompany);

            // Group Attachments by ID to select the proper preview language
            const groupedById = this.attachmentList.data.reduce((acc, obj) => {
                const key = obj.id;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});

            // Create the result array with the preferred language condition
            this.attachmentOptions = Object.keys(groupedById).map(key => {
                const items = groupedById[key];
                let preferredItem = items.find(item => item.language.toLowerCase() == this.$i18n.locale);
                if(!preferredItem) preferredItem = items.find(item => item.language.toLowerCase() == "en") || items[0];

                let label = (preferredItem.scope == TemplateScope.Global)? this.$t('Template.LandingPageNameGlobal', { id: preferredItem.id, name: preferredItem.description }) : 
                    this.$t('Template.LandingPageName', { id: preferredItem.id, name: preferredItem.description });
                return { text: label, value: preferredItem.id };
            });

            this.attachmentOptions.unshift({ value: null, text: this.$t('Attachment.DefaultPlaceholder') });

            this.$refs.templateModal.show();
        },
        parseCountryList(template) {
            // Parse template country string to retrieve country iso code
            let tempCountries = template && template.country && template.country.split('#');
            // Retrieve country object from iso code, or initialize the "empty/all countries" object
            tempCountries = tempCountries ? tempCountries.map((i) => this.countryList.filter(p => p.key == i)[0]): [{ 'key': null, 'value': this.$t('Template.AllCountries')}];
            // Clean country array in case of mispelled iso code
            tempCountries = tempCountries.filter(c => c);
            this.parseCountries = tempCountries;
        },
        getTemplateTypeIcon(templateType) {
			switch(templateType) {
				case this.TEMPLATE_TYPE_SMS:
					return ['fas', 'sms'];
				case TemplateType.Training:
					return ['fas', 'chalkboard-teacher'];
				case TemplateType.QRCode:
					return ['fas', 'qrcode'];
				case TemplateType.USB:
					return ['fab', 'usb'];
				case this.TEMPLATE_TYPE_EMAIL:
				default:
					return ['fas', 'envelope'];
			}
		},
        addTag() {
            this.parseCountries = this.parseCountries.length ? this.parseCountries.filter((item) => item.key != null) : this.parseCountries;
            this.manageTagsPlaceholder();
        },
        removeTag() {
            this.parseCountries = !this.parseCountries.length ? [{ 'key': null, 'value': this.$t('Template.AllCountries')}] : this.parseCountries.filter((item) => item.key != null);
            this.manageTagsPlaceholder();
        },
        manageTagsPlaceholder() {
            let noTags = this.parseCountries.find(c => !c.key);
            this.tagInputPlaceholder = noTags ? this.$t('Template.AvailableCountryPlaceholder') : null;
        },
        undoUnsavedChanges() {
            // Restore original template data to trigger computed variables reloading
            this.parseCountryList(this.template);
            
            this.attrList.forEach(name => {
                this.currentTemplate[name] = this.template[name];
            });
            this.currentTemplateContent = JSON.parse(JSON.stringify(this.templateContent));

            this.$refs.templateModal.hide();
		},
		manageUnsavedInfo(bvModalEvt) {
            // TODO: add disableSaveSmsBodyButton
            let modified = !(this.disableSaveInfoButton && this.disableSaveCountryButton && this.disableSaveBodyButton);
			if(modified) {
				// Prevent modal from closing
				bvModalEvt.preventDefault();
				// Trigger submit handler
				this.$bvModal.show('unsavedTemplateInfo');
			} else {
                this.handleClose(bvModalEvt);
			}
		},
		changeTab() {
			this.selectedTag = null;
			// Changes on template type are inhibited, we do not need anymore to toggle these two tags availability anymore
			// Vue.set(this.tagOptions.find(el => el.value === '{{.CompanyLogoAsDataURL}}'), 'disabled', this.template.type == this.TEMPLATE_TYPE_SMS);
			// Vue.set(this.tagOptions.find(el => el.value === '{{.QRCode}}'), 'disabled', this.template.type == this.TEMPLATE_TYPE_SMS);
		},
        async getTemplateContent(template) {
            this.templateContent = JSON.parse(JSON.stringify(template.body));
            this.templateContentType = template.type;
            this.landingPageURL = template.landing_page_url;
            this.landingPageId = template.landing_page_id;

            if(template.languages) {
                this.langOptions = this.templateContent.map((temp) => { return { value: temp.language, text: this.$langs[temp.language] } });
            } else {
                this.langOptions = [];
            }

            this.assignPreviewLanguage();

            this.currentTemplateContent = JSON.parse(JSON.stringify(this.templateContent));

            this.reloadPreview();

            this.loading = false;
        },
		async checkDuplicates() {
			if(!this.newImg) {
				return;
			}

            // This function displays a modal to prevent replacing images with the same name by error
			let index = this.repo.findIndex(img => img.name === this.newImg.name);
			if(index > -1) {
				this.$bvModal.show('irreversibleOverrideImage');
				return;
			} else {
				await this.uploadImg();
			}
		},
		async uploadImg() {
			this.loading = true;
			let img = new FormData();
			img.append('imgfile', this.newImg);
			templateService.uploadImg(img).then((image) => {
				let index = this.repo.findIndex(img => img.name === this.newImg.name);

				this.$emit('repository', index, { name: this.newImg.name, size: image.data.size, icon: image.data.icon });
				this.$refs.templateImgTable.refresh();
			}, (err) => {
				console.error("TemplateModal - uploadImg - error", err);
				this.alertMessage = 'Template.ImageFormatUnsupported';
                this.alertVariant = 'danger';
				this.$refs.templateImageAlert.showAlert();
			}).finally(() => {
				this.loading = false;
				this.newImg = null;
			});
		},
        selectRow(ref) {
            this.selectedTag = `{{.Img=` + ref[0].name + `}}`; 
		},
        copyTemplateTag() {
            if (!navigator.clipboard) {
                this.fallbackCopyTemplateTag(this.selectedTag);
                return;
            }

            navigator.clipboard.writeText(this.selectedTag).then(() => {
				this.toastTitle = this.$t('General.CopiedInClipboard', { param: this.selectedTag });
				this.selectedTag = null;
                this.$bvToast.toast(`${this.toastTitle}`, {
                    title: this.$t('Template.TemplateEditor'),
                    autoHideDelay: 2000
                })
            }, function(err) {
                this.toastTitle = this.$t('General.CopiedInClipboardError', { param: this.selectedTag });
                this.$bvToast.toast(`${this.toastTitle}`, {
                    title: this.$t('Template.TemplateEditor'),
                    autoHideDelay: 5000
                })
                console.error('Template Editor: Navigator.clipboard - Could not copy text', err);
            });
        },
        fallbackCopyTemplateTag(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
				this.toastTitle = successful? this.$t('General.CopiedInClipboard', { param: this.selectedTag }) : this.$t('General.CopiedInClipboardError', { param: this.selectedTag });
				if(successful) {
					this.selectedTag = null;
				}
                this.$bvToast.toast(`${this.toastTitle}`, {
                    title: this.$t('Template.TemplateEditor'),
                    autoHideDelay: 2000
                });
            } catch (err) {
                this.toastTitle = this.$t('General.CopiedInClipboardError', { param: this.selectedTag });
                this.$bvToast.toast(`${this.toastTitle}`, {
                    title: this.$t('Template.TemplateEditor'),
                    autoHideDelay: 5000
                });
                console.error('Template Editor: Document.execCommand - Could not copy text', err);
            }
            document.body.removeChild(textArea);
        },
        confirmDeleteTitle() {
			return this.$i18n.t("Template.ConfirmDeleteTitle");
		},
		confirmDeleteMessage() {
			return this.$i18n.t("Template.ConfirmContentDeleteMessage");
		},
		confirmDeleteTemplateLanguage(language) {
            if(this.isOnlyLanguage) {
                return;
            }
			// This function displays a modal for the user to confirm the deletion
			this.$bvModal.msgBoxConfirm(this.confirmDeleteMessage(), {
				title: this.confirmDeleteTitle(),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the user confirms the deletion, value is not null
				if (value) {
					this.deleteTemplateContentLanguage(language);
				}
			}).catch(err => {
				// The user canceled the action
				console.error("Template - confirmDeleteTemplate error", err);
			});
		},
        async addTemplateLanguage() {
            try {
                let options = this.templateContentType == TemplateType.SMS? { language: this.newTemplateLanguage, sender: 'noreply@cgphishing.com', subject : 'GoPhish email subject'} : {language: this.newTemplateLanguage};
                await templateService.createTemplateContent(this.templateId, options);
                this.alertMessage = 'Template.TemplateContentCreated';
                this.alertVariant = 'success';

                let template = await templateService.getTemplateDetails(this.templateId);
                if(template && template.data) {
                    this.template.body = template.data;
					this.template.languages = template.data.length;
                    this.getTemplateContent(this.template);
                }

                this.$emit('reload', this.templateId);
            } catch (error) {
                this.alertMessage = 'Template.TemplateContentError';
                this.alertVariant = 'danger';
            } finally {
                this.newTemplateLanguage = null;
                this.$refs.templateAlert.showAlert();
            }
        },
        async deleteTemplateContentLanguage(language) {
            try {
                await templateService.deleteTemplateContentLanguage(this.templateId, language);
                this.alertMessage = 'Template.TemplateContentDeleted';
                this.alertVariant = 'success';

                let template = await templateService.getTemplateDetails(this.templateId);
                if(template && template.data) {
                    this.template.body = template.data;
					this.template.languages = template.data.length;
                    this.getTemplateContent(this.template);
                }

                this.$emit('reload', this.templateId);
            } catch (error) {
                this.alertMessage = 'Template.TemplateContentDeleteError';
                this.alertVariant = 'danger';
            } finally {
                this.newTemplateLanguage = null;
                this.$refs.templateAlert.showAlert();
            }
        },
        checkPlaceholders(text) {
            let cleanText = text.replace(/{{.LastName}}/gm, '')
                .replace(/{{.FirstName}}/gm, '')
                .replace(/{{.Email}}/gm, '')
                .replace(/{{.Tracker}}/gm, '')
                .replace(/{{.TrackingURL}}/gm, '')
                .replace(/{{.From}}/gm, '')
                .replace(/{{.Position}}/gm, '')
                .replace(/{{.RId}}/gm, '')
                .replace(/{{.BaseURL}}/gm, '')
                .replace(/{{.URL}}/gm, '')
				.replace(/{{.CompanyLogoAsDataURL}}/gm, '')
                .replace(/{{.Img=.*}}/gm, '')
                .replace(/{{.QRCode}}/gm, '')
                .replace(/{{.CurrentDate([+-])([0-9]+)}}/gm, '');

            return cleanText.match(/{{\.*[a-zA-Z0-9\s][^}]*}}/);
        },
        /** This method was inherited from previous Template management
        async changeEditPreviewLanguage(newLanguage) {
            if(newLanguage) {
                this.previewLanguageEdit = newLanguage;
                await this.getTemplateContent();
            } else if(this.templateContent.length == 0) {
                return;
            }
            let curr = this.templateContent.filter(template => { return template.language == this.previewLanguageEdit })[0];
            if(this.editor && curr) {
                this.editor.setContent(curr.body);
            }
            this.emailSenderEdit = curr.sender;
            this.emailSubjectEdit = curr.subject;
            this.previewLanguage = this.previewLanguageEdit;
        } */
        compilePlaceholders(text, template, isPreview) {
            let landingPage = this.landingPageURL || '#';
            let baseURL = this.companyData.gp_landing_url;
            let logo = '/api/company/' + this.companyData.company.company_id + '/logo?' + Date.now();

            // Extract baseURL from landing_page_url
            if(this.landingPageURL) {
                let firstIndex = (this.landingPageURL.indexOf('://') > -1 ) ? this.landingPageURL.indexOf('://') + 3 : this.landingPageURL.length;
                baseURL = this.landingPageURL.substr(0, firstIndex);
                let secondUrlPart = this.landingPageURL.substr(firstIndex);
                let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
                baseURL += secondUrlPart.substr(0, lastIndex);
            }

            let finalText = text.replace(/{{.LastName}}/gm, `{{.FirstName}}`)
                .replace(/{{.FirstName}} {{.FirstName}}/gm, `{{.FirstName}}`) 
                .replace(/{{.FirstName}}/gm, (isPreview) ? `<span title="${this.$i18n.t('Campaigns.PlaceholderFirstname')}" class="placeholder-card">${this.$account.displayname}</span>`: this.$account.displayname)
                .replace(/{{.Email}}/gm, (isPreview) ? `<span title="${this.$i18n.t('Campaigns.PlaceholderEmail')}" class="placeholder-card">${this.$account.email}</span>`: this.$account.email)
                .replace(/{{.Tracker}}/gm, '')
                .replace(/{{.TrackingURL}}/gm, '')
                .replace(/{{.From}}/gm, template.sender)
                .replace(/{{.Position}}/gm, '')
                .replace(/{{.RId}}/gm, '')
                .replace(/{{\.Img=(.+?)\.(jpg|jpeg|png)}}/gi, (match, name, ext) => {
					return `${this.companyData.img_url}/${name}.${ext}`;
				})
                .replace(/{{.BaseURL}}/gm, baseURL)
                .replace(/{{.CompanyLogoAsDataURL}}/gm, logo)
                .replace(/<sms>/gm, '')
                .replace(/<cgtelephone>.*$/gm, '')
                .replace(/<cgsender>.*$/gm, '')
                .replace(/{{.URL}}/gm, this.templateContentType != this.TEMPLATE_TYPE_SMS ? landingPage : '<a href="' + landingPage + '">' + landingPage + '</a>')
                .replace(/{{.QRCode}}/gm, QRCode.value)
                .replace(/{{.CurrentDate([+-])([0-9]+)}}/gi, (match, symbol, days) => {
                    let date = symbol === "+" ? this.$moment().add(days, 'd') : this.$moment().subtract(days, 'd');
                    return this.$moment(date).format('DD/MM/YYYY')})
                .replace(/{{\.*[a-zA-Z0-9\s][^}]*}}/gm, (match) => { 
                    return (isPreview) ? `<span title="${this.$i18n.t('Template.WrongPlaceholderError')}" class="placeholder-card-error">${match}</span>`: '<invalid-placeholder>' 
                });

            return finalText;
        },
        compilePreview(templateContent, type, language, isPreview) {
            return new Promise((resolve, reject) => {
                try {
                    let template = templateContent.filter((t) => { return t.language == language; });
                    if(template.length == 0) {
                        template = templateContent.filter((t) => { return t.language == this.$account.language; });
                        if(template.length == 0 && this.companyData && this.companyData.company) {
                            template = templateContent.filter((t) => { return t.language == this.companyData.company.default_language; });
                        }
                        if(template.length == 0) {
                            template = templateContent.filter((t) => { return t.language == 'en'; });
                            if(template.length == 0) {
                                template = templateContent;
                            }
                        }
                    }
                    
                    template = template[0];

                    if(typeof template == 'undefined' || typeof template.body == 'undefined') {
                        return resolve('<i class="far fa-edit"></i>');
                    }

                    if(type && type == this.TEMPLATE_TYPE_SMS) {
                        var senderRegex = new RegExp(/<cgsender>(.*)<\/cgsender>/);
                        var sender = template.body ? senderRegex.exec(template.body) : null;

                        this.smsSender = sender ? sender[1] : '';
                    }

                    this.emailSubject = template.subject;
                    this.emailSender = template.sender;

                    let finalBody = isPreview? this.compilePlaceholders(template.body, template, true) : template.body;

                    // In case of SMS Template type, remove these tags from Template Editor too
                    if(this.templateType && this.templateType === this.TEMPLATE_TYPE_SMS) {
                        finalBody = finalBody.replace(/<sms>/gm, '').replace(/<cgtelephone>.*$/gm, '').replace(/<cgsender>.*$/gm, '');
                    }
                    resolve(finalBody);
                } catch (error) {
                    reject(error);
                }
            })
        },
        getCurrentHintPage(ctx) {
            let self = this;
			const promise = templateService.getTemplateHints(this.templateId, this.previewLanguage, ctx.filter, ctx.currentPage, ctx.perPage, ctx.sortBy, ctx.sortDesc);
			
			this.loading = true;
			return promise.then(result => {
                console.debug("TemplateModal - getCurrentHintPage success", this.templateId);

				self.hintTable.totalRows = result.data.count;
				return result.data.hints || [];
			}).catch((error) => {
				console.error("TemplateModal - getCurrentHintPage error", error);
			}).finally(() => {
				self.loading = false;
			});
        },
        loadHints() {
            this.$refs.hintTable.refresh();
        },
		disableForced(deleted) {
			this.currentTemplate.forced = deleted? null : 1;
		},
        showModalCreateHint() {
            this.hintData.item = {};
			this.hintData.title = 'Template.NewHint';
            this.hintData.label = 'Template.NewHint';
            
			let langs = [...this.langOptions];
			langs.unshift({ value: null, text: this.$i18n.t('General.SelectOption') });
			this.hintData.item.language = null;

			Vue.set(this.hintData.fields.find(i => i.id === 'hint_id'), 'display', false);
			Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'display', true);
			Vue.set(this.hintData.fields.find(i => i.id === 'language'), 'options', langs);

			this.$bvModal.show(this.hintData.name);
        },
        editHint(index, record) {
			this.hintData.item = record;
			this.hintData.title = 'Template.EditHint';
            this.hintData.label = 'Template.EditHint';

            Vue.set(this.hintData.fields.find(i => i.id === 'hint_id'), 'display', true);
            Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'display', false);
            Vue.set(this.hintData.fields.find(i => i.id === 'language'), 'options', this.langOptions);

			this.$bvModal.show(this.hintData.name);
        },
        async updateHint(hint) {
            this.loading = true;
            let hintPromise = null;
            let msgOK = 'Template.HintCreated';
            let msgErr = 'Template.ErrorCreateHint';

            if(hint.hint_id) {
                hintPromise = templateService.updateTemplateHint(this.templateId, hint.hint_id, hint);
                msgOK = 'Template.HintUpdated';
                msgErr = 'Template.ErrorUpdateHint';
            } else {
                if(hint.icon) {
                    hint.body = '<table><tr><td style="vertical-align:top"><img width="40" src="' + this.config.defaults.hint.icon_path + '/' + hint.icon + '" alt="" /></td><td>' + hint.body + '</td></tr></table>';
                }

                hintPromise = templateService.createTemplateHint(this.templateId, hint);
            }

            hintPromise.then(() => {
                console.debug("Template - updateHint success", hint);
                this.alertMessage = msgOK;
                this.alertVariant = 'success';
                this.loadHints();

                this.templateContent.forEach((t) => {
                    if(!hint.hint_id && t.language == hint.language) {
                        t.hints += 1;
                    }
                })

                this.$emit('reload', this.templateId, 'reloadHint', 'hints', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
                console.error("Template - updateHint error", hint, error);
                this.alertMessage = msgErr;
                this.alertVariant = 'danger';
            }).finally(() => {
                this.loading = false;
                this.$refs.templateAlert.showAlert();
            });
        },
        deleteHint(hint) {
            this.loading = true;
            templateService.deleteTemplateHint(this.templateId, hint.hint_id).then(() => {
                console.debug("Template - deleteHint success", hint.hint_id);
                this.alertMessage = 'Template.HintDeleted';
                this.alertVariant = 'success';
                this.loadHints();

                this.templateContent.forEach((t) => {
                    if(t.language == hint.language) {
                        t.hints -= 1;
                    }
                })

                this.$emit('reload', this.templateId, 'reloadHint', 'hints', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
                console.error("Template - deleteHint error", hint.hint_id, error);
                this.alertMessage = 'Template.ErrorDeleteHint';
                this.alertVariant = 'danger';
            }).finally(() => {
                this.loading = false;
                this.$refs.templateAlert.showAlert();
            });
        },
        confirmDeleteHint(index, hint) {
			this.$bvModal.msgBoxConfirm(this.$i18n.t("Template.ConfirmDeleteMessage", { param: hint.hint_id }), {
                title: this.$i18n.t("Targets.ConfirmDeleteTitle"),
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
				if (value) {
					this.deleteHint(hint);
				}
			}).catch(err => {
				console.error(err);
			});
        },
        targetEmailModal(singleLanguage) {
			console.debug("Template - targetEmailModal - ask Target mail for Test Attack Template",  this.templateName);
            this.singleLanguage = singleLanguage;
			this.$bvModal.show('test-template-attack-' + this.templateName);
		},
        testAttack() {
            this.alertParams = {
                template: this.templateType == TemplateType.SMS? this.template.name : this.emailSubject,
                recipient: this.templateType == TemplateType.SMS? this.targetNumber : this.targetEmail,
            };
            this.preventClosingModal = true;

            // Create an actual test Campaign whose params are as defined in config.json settings, targetEmail as only target and template_id as only template
            // In case of SMS template type, additional data are recipient's telephone number and template type
            templateService.testAttack(this.templateId, this.targetEmail, this.targetName, this.targetNumber, this.templateType, this.previewLanguage, this.companyData.company.company_id, this.singleLanguage)
                .then(() => {
                    this.alertMessage = 'Campaigns.TestTemplateSuccess';
                    this.alertVariant = 'success';
                }).catch((error) => {
                    var invalidTemplate = error?.response?.data.error_languages;

                    if(invalidTemplate.length) {
                        this.alertMessage = 'Campaigns.SkippedInvalidLanguages';
                        this.alertParams = { invalidTemplate: invalidTemplate.join(', ') };
                        this.alertVariant = 'danger';
                        this.dismissSecs = 30;
                    } else {
                        this.alertVariant = 'danger';
                        this.alertMessage = 'Campaigns.TestTemplateError';
                    }
                }).finally(() => {
                    this.$refs.templateAlert.showAlert();
                    this.preventClosingModal = false;
                });
        },
        async downloadTemplateHTML() {
            const blob = new Blob([this.previewLg], { type: 'text/html' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = this.template.name + ".html";
            link.click();
            URL.revokeObjectURL(link.href);
        },
        assignPreviewLanguage(languageSet) {
            // Find the Landing page version written for locale language setting
            if (!languageSet) {
                this.previewLanguage = this.templateContent.find(element => element.language == this.$i18n.locale) ? this.$i18n.locale : null;
                if (this.previewLanguage == null) {
                    // If the 'locale' version does not exist, try displaying the Template in English, otherwise pick the first available language
                    this.previewLanguage = this.templateContent.find(element => element.language == "en") ? "en" : this.templateContent[0].language;
                }
            }
        },
        async checkBodyPlaceholder(code) {
            let savedData = this.templateContent.find(tc => { return tc.language == this.previewLanguage });

            let invalidPlaceholder = this.checkPlaceholders(code);
            if(invalidPlaceholder && invalidPlaceholder.length > 0) {
                let invalidPlaceholderString = invalidPlaceholder.join(', ');
                this.alertMessage = 'Template.InvalidPlaceholderError';
                this.alertVariant = 'danger';
                this.alertParams = { placeholder: invalidPlaceholderString };
                this.$refs.templateAlert.showAlert();
                return;
            }

            if(savedData.body != code) {
                if (this.saveTimeout) {
                    clearTimeout(this.saveTimeout);
                }
                this.saveTimeout = setTimeout(this.updateTemplateBody, 1000, code);
            }
        },
        updateTemplateBody(code) {
            this.isLoaded = false;
            this.templateContent.map(tc => { if(tc.language == this.previewLanguage) { 
                tc.body = code;
            } });
            this.reloadPreview();
        },
        updateTemplateMetadata() {
            this.templateContent.map(tc => { if(tc.language == this.previewLanguage) { 
                tc.sender = this.emailSender;
                tc.subject = this.emailSubject;
                tc.smsSender = this.smsSender;
            } });
        },
        async reloadPreview() {
            this.previewLg = await this.compilePreview(this.templateContent, this.templateType, this.previewLanguage, true);
            this.previewLg = sanitizeHtml(this.previewLg, this.sanitizeOption);
            this.codePreview = await this.compilePreview(this.templateContent, this.templateType, this.previewLanguage, false);

            if(this.template.type == this.TEMPLATE_TYPE_SMS && this.previewLg) {
                this.previewLg =  `<div class="chat"><div class="message">`+ this.previewLg + `</div></div>`;
            }
        },
        saveTemplate() {
            let savedData = this.templateContent.find(t => { return t.language == this.previewLanguage });
			let previousData = this.currentTemplateContent.find(t => { return t.language == this.previewLanguage });

            savedData.sender = this.emailSender;
            savedData.subject = this.emailSubject;

            var smsRegex = new RegExp(/<sms>(.*)<\/sms>/);
            var smsBody = smsRegex.exec(savedData.body);

            if(this.template.type == this.TEMPLATE_TYPE_SMS && !smsBody) {
                savedData.body = `<sms>${savedData.body}<cgtelephone>{{.Position}}</cgtelephone><cgsender>${this.smsSender}</cgsender></sms>`;
            } else {
                savedData.body = savedData.body.replace(/<sms>(.*)<cgtelephone>{{.Position}}<\/cgtelephone>(<cgsender>(.*)<\/cgsender>){0,1}<\/sms>/, `<sms>$1<cgtelephone>{{.Position}}</cgtelephone><cgsender>${this.smsSender}</cgsender></sms>`);
            }

            if(JSON.stringify(previousData) === JSON.stringify(savedData)) {
                return;
            }

            templateService.updateTemplateContent(this.templateId, this.previewLanguage, savedData).then(() => {
                console.debug("TemplateModal - saveTemplate success", this.templateId);
                this.alertVariant = 'success';
				this.alertMessage = 'Template.TemplateUpdated';
				
				this.templateContent.forEach((template) => {
					if(template.language == this.previewLanguage) {
                        template.sender = this.emailSender;
						template.subject = this.emailSubject;
                        template.smsSender = this.smsSender;
					} 
				})

                // Update saved Template content
                this.currentTemplateContent = JSON.parse(JSON.stringify(this.templateContent));

                this.$emit('reload', this.templateId, 'reloadBody', 'body', JSON.parse(JSON.stringify(this.templateContent)));
            }).catch((error) => {
				console.error("Template - saveTemplate error");
				this.alertVariant = 'danger';
				if(error.response.status == 400) {
					this.alertMessage = 'Template.ErrorUpdateTemplateTooBig';
				} else {
					this.alertMessage = 'Template.ErrorUpdateTemplate';
				}
            }).finally(() => {
                this.loading = false;
                this.$refs.templateAlert.showAlert();
			});
        },
        async copyTemplate() {
            let alertVariant = '';
			let alertMessage = '';
            try {
                await templateService.copyTemplate(this.templateId, { name: this.currentTemplate.name, companyId: this.templateCompany } );
                alertVariant = 'success';
				alertMessage = 'Template.CopySuccess';
			} catch (error) {
				alertVariant = 'danger';
				alertMessage = 'Template.CopyError';
			} finally {
                this.$emit('copied', alertMessage, alertVariant);
                this.$refs.templateModal.hide();
			}
        },
		checkSubjectPlaceholder(data) {
			let invalidSubjectPlaceholder = this.checkPlaceholders(data);
			if(invalidSubjectPlaceholder && invalidSubjectPlaceholder.length > 0) {
				let invalidPlaceholderString = invalidSubjectPlaceholder.join(', ');
				this.alertMessage = 'Template.InvalidPlaceholderError';
				this.alertVariant = 'danger';
				this.alertParams = { placeholder: invalidPlaceholderString };
				this.$refs.templateAlert.showAlert();
				return;
			} 
		},
        checkSMSSender(data) {
            var senderRegex = new RegExp(/[a-zA-Z0-9 .]{0,11}/);
            var sender = senderRegex.exec(data);

            if(data && (data.length > 11 || sender[0] !== data )) {
				this.alertMessage = 'Template.ValidSender';
				this.alertVariant = 'danger';
				this.$refs.templateAlert.showAlert();
				return;
			} 
		},
        emailIFrameLoaded() {
            // Email iFrame and its elements will be available for manipulation only after this point
            this.addLandingPageLink('templatePreviewEmailIframe');
            this.isLoaded = true;
        },
        smsIFrameLoaded() {
            // SMS iFrame and its elements will be available for manipulation only after this point
            this.addIframeStyle();
            this.addLandingPageLink('templatePreviewIframe');
            this.isLoaded = true;
        },
        addIframeStyle() {
            // Add dynamic CSS to iFrame
            var frame = document.getElementById("templatePreviewIframe");
            let style = frame.contentWindow.document.createElement('style');
            style.type = 'text/css';
            style.appendChild(frame.contentWindow.document.createTextNode(`<style type='text/css'>  
                .chat {
                    width: 300px;
                    border: solid 1px #EEE;
                    padding: 10px;
                }
                .message {
                    border-radius: 20px;
                    padding: 8px 15px;
                    margin-right: 25%;
                    background-color: #eee;
                    position: relative;
                    width: fit-content;
                    font-family: "Avenir", Helvetica !important;
                }
                .message:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    left: -7px;
                    height: 20px;
                    width: 20px;
                    background: #eee;
                    border-bottom-right-radius: 15px;
                }
                .message:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: -10px;
                    width: 10px;
                    height: 20px;
                    background: white;
                    border-bottom-right-radius: 10px;
                }
                </style>`));
            frame.contentWindow.document.head.appendChild(style);
        },
        addLandingPageLink(iFrameName) {
            if(this.companyData && this.companyData.company) {
                var frame = document.getElementById(iFrameName);
                frame.contentWindow.document.body.onclick = (event) => {
                    if(event.target.classList.contains("landing-link") || (event.target.closest( "a" ) ? event.target.closest( "a" ).classList.contains("landing-link") : false )) {
                        this.openCompanyLandingPage(event);
                    }
                }
            }
        },
        async openCompanyLandingPage(event) {
            event.preventDefault();
            var landingData = await companyService.getLandingPage(this.companyData.company.company_id, this.templateId, this.previewLanguage);
            var newPage = window.open("");

            let baseURL = this.companyData.gp_landing_url;

            // Extract baseURL from landing_page_url
            if(this.landingPageURL) {
                let firstIndex = (this.landingPageURL.indexOf('://') > -1 ) ? this.landingPageURL.indexOf('://') + 3 : this.landingPageURL.length;
                baseURL = this.landingPageURL.substr(0, firstIndex);
                let secondUrlPart = this.landingPageURL.substr(firstIndex);
                let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
                baseURL += secondUrlPart.substr(0, lastIndex);
            }

            let finalLP = landingData.data.html.replace(/{{.BaseURL}}/gm, baseURL);

            newPage.document.write(finalLP);
            newPage.document.close();
        },
        updateTemplateBasicInfo() {
            this.loading = true;

            this.currentTemplate.country = this.parseCountries.map(c => c.key).join('#') || null;

            let info = {
                name: this.currentTemplate.name,
                tags: this.currentTemplate.tags,
                landing_page_url: this.currentTemplate.landing_page_url,
                landing_page_id: this.currentTemplate.landing_page_id || null,
                difficulty: this.currentTemplate.difficulty,
                company_id: this.currentTemplate.company_id,
                has_attachment: this.currentTemplate.type != this.TEMPLATE_TYPE_SMS ? Number(this.currentTemplate.has_attachment) : null,
                attachment_id: this.currentTemplate.attachment_id,
                deleted: this.currentTemplate.deleted,
				forced: this.currentTemplate.forced,
                country: this.currentTemplate.country,
                current_company_id: this.companyData.company_id
            };

            templateService.updateTemplate(this.templateId, info).then(async () => {
                console.debug("Template - updateTemplateBasicInfo success", this.templateId);

                // Update landing page links in preview tab
                if(this.template.landing_page_url != this.landingPageURL && this.template.landing_page_url != '') {
                    this.landingPageURL = this.template.landing_page_url;
				}

                this.attrList.forEach(name => {
                    this.template[name] = this.currentTemplate[name];
                });

				this.reloadPreview();
				this.$emit('reload', this.templateId);

                this.alertVariant = 'success';
                this.alertMessage = 'Template.TemplateUpdated';
            }).catch((error) => {
                console.error("Template - updateTemplateBasicInfo error", this.templateId, error);
                this.alertVariant = 'danger';
                this.alertMessage = 'Template.ErrorUpdateTemplate';
            }).finally(() => {
                this.loading = false;
                this.$refs.templateAlert.showAlert();
            });
        }
    },
    async mounted() {
        // Retrieve all countries
        let locale = this.$i18n.locale;
        this.isoCountries = await this.$isoCountriesLanguages.getCountries(locale);

        // Modify isoCountries to fit tag-input options
        this.countryList = Object.keys(this.isoCountries).map((c) => { return { key: c, value: this.isoCountries[c]} });
    
        if(this.$checkPermission('view-template-hint')) {
            templateService.getHintIcons().then((res) => {
                Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'options', res && res.data);
            }).catch((error) => {
                console.error("TemplateModal - getHintIcons - error", error);
                Vue.set(this.hintData.fields.find(i => i.id === 'icon'), 'options', []);
            });
        }

        let hintOptions = [ 'urgency', 'grammar', 'content', 'address', 'link', 'sender', 'structure' ];

        this.tagOptions = [
            { text: this.$i18n.t('General.LastName'), value: '{{.LastName}}' },
            { text: this.$i18n.t('General.FirstName'), value: '{{.FirstName}}' },
            { text: this.$i18n.t('General.Email'), value: '{{.Email}}' },
            { text: this.$i18n.t('General.Tracker'), value: '{{.Tracker}}' },
            { text: this.$i18n.t('General.TrackingURL'), value: '{{.TrackingURL}}' },
            { text: this.$i18n.t('General.Sender'), value: '{{.From}}' },
            { text: this.$i18n.t('General.Number'), value: '{{.Position}}' },
            { text: this.$i18n.t('General.RId'), value: '{{.RId}}' },
            { text: this.$i18n.t('General.BaseURL'), value: '{{.BaseURL}}' },
            { text: this.$i18n.t('General.URL'), value: '{{.URL}}' },
			{ text: this.$i18n.t('General.CompanyLogo'), value: '{{.CompanyLogoAsDataURL}}', disabled: false },
            { text: this.$i18n.t('General.QRCode'), value: '{{.QRCode}}', disabled: false },
            { text: this.$i18n.t('General.Date'), value: '{{.CurrentDate+0}}', disabled: false }

		];

        this.typeOptions = [
            { value: this.TEMPLATE_TYPE_EMAIL, text: this.$t('Analytics.TemplateTypes.Email') }, 
			{ value: this.TEMPLATE_TYPE_SMS, text: this.$t('Analytics.TemplateTypes.Sms') },
			{ value: this.TEMPLATE_TYPE_QRCODE, text: this.$t('Analytics.TemplateTypes.QRCode') },
			{ value: this.TEMPLATE_TYPE_USB, text: this.$t('Analytics.TemplateTypes.USB') },
			{ value: this.TEMPLATE_TYPE_TRAINING, text: this.$t('Analytics.TemplateTypes.Training') }
        ]
		
		this.tagOptions.unshift({ value: null, text: this.$i18n.t('Template.SelectTagToCopy') });

        Vue.set(this.hintData.fields.find(i => i.id === 'class'), 'options', hintOptions);

        let configdata = await syspropService.getConfig();
        this.config = configdata.data;

        if(!this.isCampaign) {
            let companyListData = await companyService.getCompanyList();
            this.companyList = companyListData.data.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });
        }

        this.emailDomain = this.companyData && this.companyData.company && this.companyData.company.email_domain && this.companyData.company.email_domain.split(/,| /).filter( dom => { return  dom!=''} );
        this.validDomain = this.emailDomain ? {
            count: this.emailDomain.length,
            domain: this.emailDomain.join(', ')
        } : {};
    },
    created() {
        this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
		this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
		this.TEMPLATE_TYPE_USB = TemplateType.USB;
		this.TEMPLATE_TYPE_TRAINING = TemplateType.Training;

        this.attrList.push('name', 'tags', 'scope');
        if (this.$checkPermission('view-landing-page')) this.attrList.push('landing_page_url', 'landing_page_id');
        this.attrList.push('difficulty', 'type', 'has_attachment', 'attachment_id', 'company_id', 'deleted', 'forced', 'country');

        this.imageRepoFields = [
			{ key: 'icon', label: '',  sortable: false, class: 'text-center max-60' },
			{ key: 'name', label: this.$t('Template.Name'), sortable: true, class: 'text-ellipsis' },
			{ key: 'size', label: this.$t('General.Size'),  sortable: true, class: 'max-200' }
		];
    }
};
</script>

<style lang="less">
.modal-body-full-width.template {
    padding: 0;
    min-height: 398px;
    .no-bg {
        background-color: unset;
    }
    iframe {
        min-height: 398px;
    }
    .preview-template {
        height: 400px;
        // overflow: auto;
        border: @cg-border-light;
        &.sms-type {
            height: auto;
            border: unset;
        }
    }
    .sms-preview {
        border: @cg-border-light;
        height: 200px;
        &.full-body {
            height: 500px;
            iframe {
                min-height: 500px;
            }
        }
        iframe {
            min-height: 200px;
        }
    }
    .separator {
        border-bottom: @cg-border-light;
        &.company:last-of-type {
            border-bottom: none;
        }
    }
    .line-height-30 {
        line-height: 30px;
    }
    .tab-content>.tab-pane {
        height: auto;
    }
}
pre[class*="language-"] {
    overflow: initial;
}
.prism-editor__line-numbers {
    position: sticky;
    left: 0px;
}
.sms-type .prism-editor-wrapper {
    & code {
        white-space: normal;
    }
}
.sms-preview-spinner {
    right: 35px; 
    margin-top: 16px;
}
#hintListTable tbody td {
    word-break: break-word;
}
#uploadImage {
    margin-top: 40px;
    overflow-y: hidden;
	.white-overlay {
		top: 40px !important;
		.alert-wrapper {
			width: 500px !important;
		}
	}
}

#templateImgTable {
	th {
		&.max-60 {
			width: 60px !important
		}
		&.max-200 {
			width: 200px !important;
		}
	}
	td {
		vertical-align: middle;
	}
	&.table tbody td.text-ellipsis {
		position: relative;
	}
	&.table tbody td.text-ellipsis span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: absolute;
		width: 96%;
	}
	td.text-ellipsis:before {
		content: '';
		display: inline-block;
	}
    tr:hover {
        background-color: #ffd8b0;
    }
    tr.b-table-row-selected {
        background-color: rgba(255, 151, 18, 0.5);
    }
}
#irreversibleOverrideImage {
	& .modal-dialog {
		padding: 40px;
		svg.exclamation-triangle {
			height:60px;
			width:60px; 
			color:#dc3545;
		}
	}
	& .cg-modal-footer {
		border-top: @cg-border-light;
		button:last-of-type {
			margin: 0 0 0 20px;
		}
		button:only-of-type {
			margin: 0;
		}
	}
}

// Large devices (desktops, 992px and up)
@media only screen and (max-width: 991px) {
    .modal-dialog.modal-xl {
        max-width: unset;
    }
    .modal-body-full-width.template {
        iframe {
            min-height: 378px;
        }    
        .preview-template {
            height: 380px;
        }
    }
}

@preview-height: 450px;

a {
    color: #0071EB;
}
.template-card-modal-footer {
    border: none !important;
    width: 100%;
    .col {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
.placeholder-card {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: @cg-secondary-color;
}
.placeholder-card:after {
    content: '*';
    color: @cg-secondary-color;
}
.placeholder-card-error {
    cursor: pointer;
    text-decoration: line-through;
    text-decoration-color: @cg-error;
}
.placeholder-card-error:after {
    content: '*';
    color: @cg-error;
}
.template-info {
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    &:only-of-type {
        border-bottom: none;
    }
}
#languageListTable .action-column {
	width: 120px !important;
}
#template-modal {
    .modal-dialog {
        max-width: 90%;
        max-height: 95%;
    }
    .modal-body {
        padding: 0px;
    }
    .ql-editor {
        height:@preview-height;
    }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.modal-dialog .white-overlay .alert-wrapper {
    top: 91px;
    max-width: unset;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-91px);
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: @cg-primary-color;
    background-color: @cg-primary-color;
}
.custom-switch .custom-control-label {
    &::before {
        border-color: @cg-dark-gray-text-color;
        background-color: @cg-secondary-light-text-color;
    }
    &::after {
        color: @cg-dark-gray-text-color;
        background-color: @cg-dark-gray-text-color;
    }
}

@media only screen and (min-width: 1200px) {
    .modal-dialog .white-overlay .alert-wrapper {
        width: 866px;
    }
}
@media only screen and (min-width: 990px) and (max-width: 1199px) {
    .modal-dialog .white-overlay .alert-wrapper {
        width: 766px;
    }
}
@media only screen and (min-width: 767px) and (max-width: 989px) {
    .modal-dialog .white-overlay .alert-wrapper {
        width: 666px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 766px) {
    .modal-dialog .white-overlay .alert-wrapper {
        width: 466px;
    }
    .modal-body-full-width.template .tab-content > .tab-pane {
        height: auto;
    }
}
@media only screen and (max-width: 575px) {
    .modal-dialog .white-overlay .alert-wrapper {
        width: 91%;
        top: 71px;
    }
}
</style>
